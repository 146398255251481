.container{
  width:100vw;
  height: 600px;
  background: url('./../../../public/navBackground.avif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-bottom: 2px solid #25b148;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title{
  width: 650px;
  color: white;
  text-shadow: 
  2px 2px 4px #04242d, 
  -2px -2px 4px #04242d, 
  2px -2px 4px #04242d, 
  -2px 2px 4px #04242d,
  1px 1px 3px #04242d, 
  -1px -1px 3px #04242d, 
  1px -1px 3px #04242d, 
  -1px 1px 3px #04242d;  padding: 5px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 24pt;
}

.description{
  color: white;
  width: 580px;
  text-align: center;
  margin-top: 0px;
  border-radius: 20px;
  padding: 5px;
  text-shadow: 
  2px 2px 4px #04242d, 
  -2px -2px 4px #04242d, 
  2px -2px 4px #04242d, 
  -2px 2px 4px #04242d,
  1px 1px 3px #04242d, 
  -1px -1px 3px #04242d, 
  1px -1px 3px #04242d, 
  -1px 1px 3px #04242d;}

@media screen and (max-width: 740px) {
 .title{
  width: 350px;
 }
 .description{
  width: 300px;
 }
}