.container{
  padding: 50px 10px 60px 10px;
  background-color: #04242d;
  color: white;
  min-height: 350px;
  width: 100vw -30px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  border-bottom: 2px solid #25b148;
 
  align-items: center;
  justify-items: center;
}

.heading{
  color: white;
  margin-top: 0;
  margin-bottom: 0px;
}

.description{
  margin: 0px;
}

.row{
  width: 600px;
  display: grid;
  align-items: center;
  grid-template-columns: 50px 1fr ;
}

.row1{
  margin-left: -500px;
}

.row2{
  margin-left: 0px;
}

.row3{
  margin-left: 500px;
}


@media screen and (max-width: 1100px) {
  .row1{
    margin: 0px;
  }
  .row2{
    margin: 0px;
  }
  .row3{
    margin: 0px;
  }
}

@media screen and (max-width: 670px) {
  .row{
    width: 380px;
  }
  .heading{
    font-size: 16pt;
  }
  .description{
    font-size: 10pt;
  }
 }