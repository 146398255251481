.container{
  padding: 60px 10px 60px 10px;
  background-color: #04242d;
  color: white;
  width: 100vw -30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 2px solid #25b148;
  flex-wrap: wrap; 
}

.clientContainer{
  height: 210px;
  width: 210px;
  background-color: #25b148;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #04242d;
}

.client1{
  background-color: lighten(#25b148, 7%);
}

.client2{
  background-color: lighten(#25b148, 21%);
}

.client3{
  background-color: lighten(#25b148, 14%);
}

.client4{
  background-color: lighten(#25b148, 28%);

}

@media screen and (max-width: 1100px) {
  .container {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: 300px 300px;
  }
}

@media screen and (max-width: 570px) {
  .container {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr; 
    grid-template-rows: 300px 300px 300px 300px;
  }
}