.container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.logo{
  background: url('./../../../public/tccgLogo.webp');
  background-size: contain;
  background-repeat: no-repeat;
  width: 150px;
  height: 50px;
  margin-left: 15px;
}

.nav{
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0px 30px 0px 0px;
}

button{
  color: white;
  margin: 0px;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  background-color: transparent;
  border: none;
  font-size: 18pt;
  cursor: pointer;
  
  &:hover{
    color: #25b148;
  }
}

.mobileDropdownButton{
  width: 100vw;
  background-color: #25b148 ;
  border: solid;
  border-color: #2F455C;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  text-align: start;
}

.dropdown {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  flex-direction: column;
  padding-top: 70px;
  position: fixed;
  width: 100vw;
  top: 0;
  background-color: #25b148;
}

.dropdownVisible {
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: 740.1px){
  .dropdown{
    display: none;
  }
  .menuButton{
    display: none;
  }

}

@media screen and (max-width: 740px) {
  button{
    color: white;
    &:hover{
      color: #2F455C;
    }
  }

  .dropdown{
    transition: display 0.3s ease;
    flex-direction: column;
    padding-top: 70px;
    position: fixed;
  }
  .menuButton{
    cursor: pointer;
    margin: 0px 30px 0px 0px;
  }
  .nav{
    display: none;
  }   
}