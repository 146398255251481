.container{
  padding: 60px 30px 35px 30px;
  background-color: #04242d;
  color: white;
  height: 100%;
  border-bottom: 2px solid #25b148;
 
}

.heading{
  color: white;
  margin-top: 0;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
}

.gridElement{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-self: center;
  justify-self: center;
  // background-color: white;
  height: 250px;
  width: 250px;
}

h2{
  color: white;
  font-size: 20pt;
}

@media screen and (min-width: 1080px) and (max-width: 1080px) {
  .grid{
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1100px) {
  .grid{
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 540px) {
  .grid{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    row-gap: 0px;
  }
}