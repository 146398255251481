.container{
  padding: 30px 30px 60px 30px;
  background-color: #04242d;
  color: white;
  width: 100vw -30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.contactContainer{
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  width: 155px;
}

.heading{
  margin: 0px;
}

.info{
  margin: 10px 0px 0px 0px;
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 1100px) {
  .contactContainer {
    margin-left: 0px;
  }
}


@media screen and (max-width: 500px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0px;
  }
  .contactContainer{
    margin-bottom: 60px;
  }
}